$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;

.main {
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 110px;
    @media (min-width: $breakpoint-desktop) {
        margin-top: 140px;
    }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  h2 {
    margin-top: 30px;
  }
}


.aboutMain {
  background-color: white;
  margin-top: 30px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.5);
  width: 90%;
  border-radius: 8px;
  p {
    margin-top: 30px;
  }
  @media (min-width: $breakpoint-tablet) {
    width: 90%;
  }
}

.sections {
  background-color: white;
  margin-top: 30px;
  width: 100%;
  padding-top: 10px;
  .section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    line-height: 28px;
    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      width: 80%;
      &:nth-child(1) {
        img {
          order: 2;
        }
      }
      &:nth-child(3) {
        img {
          order: 2;
        }
      }
      &:nth-child(2) {
        ul {
          text-align: end;
        }
      }
    }
    img {
      width: 50%;
      border-radius: 18px;
      box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.5);
      @media (min-width: $breakpoint-tablet) {
        width: 30%;
      }
    }
    ul {
      margin-top: 30px;
      width: 90%;
    }
  }
}
.button {
  background-color: white;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 30px;
  @media (min-width: $breakpoint-tablet) {
    padding-top: 60px;
  }
  button {
    padding: 8px;
    border-radius: 8px;
    border: 0px;
    background-color: #227ba9;
    color: white;
    @media (min-width: $breakpoint-tablet) {
      width: 40%;
    }
  }
}
