$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;

.main {
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 100px;

    @media (min-width: $breakpoint-desktop) {
        margin-top: 130px;
    }

    a {
        text-decoration: none;
        color: black;

        &:hover {
            color: #227ba9;
        }
    }
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
        margin-top: 30px;
    }
}

.sponsors {
    background-color: white;
    margin-top: 30px;
    box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.5);
    width: 90%;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    img {
        cursor: pointer;
        width: 180px;
        &:nth-child(3) {
            border-radius: 10%;
        }
    }

    @media (min-width: $breakpoint-desktop) {
        flex-direction: row;
        justify-content: space-around;
        padding: 60px;
    }
}