$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$mobile-font-size: 14px;
$mobile-padding-button: 12px;
$desktop-font-size: 16px;
$desktop-padding-button: 14px;
$border-radius: 8px;

.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 100px;
    width: 100%;
    margin: auto;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 20;
    box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
    @media (min-width: $breakpoint-desktop) {
        padding-left: 20px;
        padding-right: 20px;
        height: inherit;
    }
}

.Logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    img {
        width: 60px;

        @media (min-width: $breakpoint-desktop) {
            width: 100px;
        }
    }

    .ataa {
        width: 120px;
        margin-left: 10px;
        @media (min-width: $breakpoint-desktop) {
            width: 200px;
            margin-left: 30px;
        }
    }

    .burger-icon {
        width: 30px;
        height: 30px;
        padding: 5px 2px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 101;

        .burger-line {
            width: 100%;
            height: 2px;
            background-color: black;
            transition: .5s;
        }

        @media (min-width: $breakpoint-desktop) {
            display: none;
        }
    }

    ;

    .burger-icon_active {
        justify-content: center;
        position: relative;

        .burger-line {
            &:nth-child(1) {
                position: absolute;
                transform: rotate(45deg);
                transition: .5s;
            }

            ;

            &:nth-child(2) {
                opacity: 0;
                transition: .1s;
            }

            ;

            &:nth-child(3) {
                position: absolute;
                transform: rotate(-45deg);
                transition: .5s;
            }
        }
    }
}

.right-side {
    display: flex;
    align-items: center;
    .Nav {
        display: flex;
        position: fixed;
        left: -100%;
        top: 0;
        gap: 30px;
        background-color: #227ba9;
        color: white;
        height: 100%;
        width: 80%;
        z-index: 100;
        padding: 4rem 1rem;
        transition: .5s;

        @media (min-width: $breakpoint-desktop) {
            position: static;
            height: auto;
            width: auto;
            padding: 0;
            background-color: transparent;
            color: black;
            margin-right: 30px;
        }

        .Buttons {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin-top: 4rem;
            gap: 20px;
            width: 100%;

            @media (min-width: $breakpoint-desktop) {
                padding: 0;
                margin: 0;
                gap: 30px;
                flex-direction: row;
                width: auto;
            }

            .list {
                padding-bottom: 20px;
                border-bottom: 1px solid white;

                @media (min-width: $breakpoint-desktop) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    padding: 1rem 0;

                    &:hover .button-list {
                        display: flex;
                    }
                }

                .button-item {
                    margin-bottom: 0;
                    cursor: pointer;
                    color: white;
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    text-decoration: none;

                    img {
                        width: 15px;
                        transition: .3s;
                    }

                    .arrow-active {
                        transform: rotate(-180deg);
                        transition: .3s;
                    }

                    @media (min-width: $breakpoint-desktop) {
                        background-color: #dedede;
                        cursor: pointer;
                        font-size: $desktop-font-size;
                        padding: $desktop-padding-button;
                        border: 0px;
                        border-radius: $border-radius;
                        color: black;

                        &:hover {
                            background-color: #227ba9;
                            color: white;
                            border: none;
                            border-radius: 10px;
                        }

                        img {
                            display: none;
                        }
                    }
                }

                .button-list {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    padding: 1rem 2rem;
                    font-size: 14px;
                    gap: 13px;
                    display: none;

                    a {
                        width: 100%;
                        color: white;
                        text-decoration: none;

                        li {
                            border-bottom: .5px solid white;
                            padding-bottom: .7rem;
                            

                            @media (min-width: $breakpoint-desktop) {
                                border: none;
                                padding: 0;
                                color: black;
                            }
                        }
                    }

                    @media (min-width: $breakpoint-desktop) {
                        cursor: pointer;
                        position: absolute;
                        top: 5rem;
                        width: 160px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background-color: white;
                        border: 1px solid #227ba9;
                        border-radius: 10px;
                        padding: 0px;
                        font-size: 14px;
                        gap: 8px;
                        display: none;

                        .button-list-item {
                            @media (min-width: $breakpoint-desktop) {
                                padding: .5rem .5rem;
                                width: 100%;

                                &:hover {
                                    background-color: #227ba9;
                                    color: white;
                                    border: none;
                                    border-radius: 10px;
                                }
                            }

                        }
                    }
                }

                ;

                .button-list_active {
                    display: flex;
                    animation: opacity .4s ease-in 1 forwards;
                    

                    @media (min-width: $breakpoint-desktop) {
                        display: none;
                    }
                }

                @keyframes opacity {
                    0% {
                        opacity: 0
                    }

                    100% {
                        opacity: 1
                    }
                }
            }
        }
    }

    ;

    .nav-active {
        left: 0;
        transition: .5s;
    }

    .buttonEnd {
        background-color: #dedede;
        cursor: pointer;
        padding: $mobile-padding-button;
        font-size: $mobile-font-size;
        border: 0px;
        border-radius: $border-radius;

        @media (min-width: $breakpoint-desktop) {
            font-size: $desktop-font-size;
            padding: $desktop-padding-button;

            &:hover {
                background-color: #227ba9;
                color: white;
                border: none;
                border-radius: 10px;
            }
        }
    }
}