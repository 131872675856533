$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$mobile-font-size: 14px;
$mobile-padding-button: 16px;
$desktop-font-size: 16px;
$desktop-padding-button: 18px;
$border-radius: 8px;

button {
  cursor: pointer;
}

.buttonEnd {
  font-size: $mobile-font-size;
  padding: $mobile-padding-button;
  border-radius: $border-radius;
  border: 0px;
  background-color: #227ba9;
  color: white;

}

.courses {
  margin: 4rem 0;
  max-width: 85rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0 1rem;
  width: 100%;

  img {
    display: none;
  }

  @media (min-width: $breakpoint-tablet) {
    align-items: center;
  }

  .heading {
    text-align: center;
  }

  .courseCards {
    margin: 40px 0;
    gap: 2rem;
    display: flex;
    flex-direction: column;

    .courseDiv {
      box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.5);
      border-radius: 4px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    .coursecard {
      margin-top: 30px;

      @media (min-width: $breakpoint-tablet) {
        margin-top: 0px;
      }

      button {
        display: none;
      }

      h3 {
        font-size: 24px;
      }
    }

    .button {
      font-size: 14px;
      padding: 8px;
      border-radius: $border-radius;
      border: 0px;
      background-color: #227ba9;
      color: white;
      text-decoration: none;
    }

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      .courseDiv {
        width: calc(100% /3);
        align-items: center;
      }
    }
  }
}

.main {
  background-color: #f4f4f4;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 85rem;
  width: 100%;
  padding: 0 1rem;
}

.containerAbout {
  @media (min-width: $breakpoint-tablet) {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  @media (max-width: $breakpoint-desktop) {
    justify-content: center;
  }
}

.recsContainer {
  position: relative;
  width: 100%;

  img {
    position: absolute;
  }

  .rec1 {
    left: 50px;
  }

  .rec2 {
    top: -40px;
  }
  @media (min-width: $breakpoint-tablet) {
    .rec1 {
      top: 90px;
      left: 70px;
    }

    .rec2 {
      top: 40px;
      left: 16px;
    }
  }
}

.aboutUs {
  margin-top: 40px;
  padding-bottom: 40px;

  p {
    margin-top: 20px;
    text-align: center;
  }

  button {
    font-size: 14px;
    padding: 8px;
    border-radius: $border-radius;
    border: 0px;
    background-color: #227ba9;
    color: white;
    margin-top: 30px;
  }

  img {
    display: none;
  }

  @media (min-width: $breakpoint-tablet) {
    max-width: 430px;
    width: auto;
  }
}


.background {
  display: none;
  max-width: 100%;

  @media (min-width: $breakpoint-desktop) {
    display: block;
  }
}

.icons {
  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.5);
  border-radius: 4px;
  padding: 30px;

  h3 {
    font-size: 30px;
    text-align: center;
    width: 18rem;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }

  @media (min-width: $breakpoint-tablet) {
    text-align: center;
    h3 {
      font-size: 40px;
      width: auto;
    }
  }
}

.education {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4rem 0;
  max-width: 85rem;
  padding: 0 1rem;
  width: 100%;

  h3 {
    margin-top: 10px;
  }

  button {
    font-size: 14px;
    padding: 8px;
    border-radius: $border-radius;
    border: 0px;
    background-color: #227ba9;
    color: white;
    margin-top: 30px;
  }
}

.teachers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  margin-top: 0px;
  @media(min-width: $breakpoint-desktop) {
    margin-top: 10px;
  }
  padding: 2rem 1rem;
}

.teacherCard {
  background-color: white;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.5);
  border-radius: 4px;
  padding: 30px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    border-radius: 10px;
  }

  button {
    margin: 0;
  }

  h3 {
    margin-top: 20px;
  }

  @media (min-width: $breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    h3,
    p {
      text-align: center;
    }
  }
}

.teacherCardDisabled {
  @extend .teacherCard;
  button,
  p,
  img,
  h3 {
    opacity: 0.5;
  }
}

.main {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.blogs {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 50px;
  width: 100%;

  img {
    cursor: pointer;
  }

  button {
    background-color: transparent;
    border: 0px;
    font-size: $mobile-font-size;
    margin-top: 10px;
  }

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }
}

.subscribe {
  display: flex;
  flex-direction: column;
  max-width: 70rem;
  gap: 30px;
  margin: 4rem 0;
  text-align: center;
  padding: 0 1rem;

  p {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.inputDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;

  @media (min-width: $breakpoint-tablet) {
    width: 50%;
  }

  button {
    position: absolute;
    right: 2px;
    font-size: 14px;
    padding: 8px;
    border-radius: $border-radius;
    border: 0px;
    background-color: #227ba9;
    color: white;
  }

  input {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    padding-right: 120px;
    padding-left: 10px;
  }
}
