$breakpoint-desktop: 992px;

.centered-modal {
    p {
        margin: 0;
    }
    #contained-modal-title-vcenter {
        font-size: 18px;
        font-weight: bolder;
    }
    .modal-body {
        padding: 0;
    }
    #inputs {
        display: flex;
        flex-direction: column;
        @media (min-width: $breakpoint-desktop) {
            flex-direction: row; 
            justify-content: space-between;
        };
        padding: 8px 16px;
        gap: 0px;
        .left-side-modal {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            @media (min-width: $breakpoint-desktop) {
                width: 45%;
            }
            div {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 4px;

                input,
                select {
                    padding: 10px;
                    border-radius: 8px;
                    border: 1px solid #dedede;
                }

                select {
                    cursor: pointer;
                }
            }

            .modalButton {
                background-color: #227ba9;
                border: 0px;
            }

            .input-error {
                border-color: #fc8181;
            }

            .errorMessage {
                color: #fc8181;
                font-size: 14px;
            }
        }

        .price {
            display: flex;
            flex-direction: row !important;
            margin-top: 12px;
        }
    }

    .right-side-modal {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;
        @media (min-width: $breakpoint-desktop) {
            margin-top: 0;
            width: 50%;
        }
        p {
            text-align: center;
        }
        .MuiFormControlLabel-root {
            height: 26px;
        }
        div {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;

            input, select {
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #dedede;
            }
            select {
                cursor: pointer;
            }
        }
    }
}