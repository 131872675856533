$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$mobile-font-size: 16px;
$mobile-padding-button: 12px;
$desktop-font-size: 18px;
$desktop-padding-button: 14px;
$border-radius: 8px;

.mainSlider {
    background-color: #F4F4F4;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 100px;
    @media (min-width: $breakpoint-desktop) {
        margin-top: 135px;
    }
}

.swiperSlide {
    display: none;
    padding: 0 1rem !important;

    @media (min-width: $breakpoint-tablet) {
        display: block;
        max-width: 85rem;
        width: 100%;
    }
}

.sliderBackground {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F4F4F4;
    margin: auto;
    padding-bottom: 30px;

    .sliderMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        width: 40%;
        gap: 30px;

        p {
            font-size: 50px;
            color: rgb(26, 33, 72);
        }

        button {
            font-size: $desktop-font-size;
            padding: $desktop-padding-button;
            border-radius: $border-radius;
            border: 0px;
            background-color: #227ba9;
            color: white;
        }
    }
}

.mobileSlider {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-bottom: 30px;

    p {
        font-size: 30px;
        text-align: center;
        margin-top: 30px;
    }

    img {
        width: 100%;
        margin-top: 30px;
    }

    button {
        font-size: $mobile-font-size;
        padding: $mobile-padding-button;
        border-radius: $border-radius;
        border: 0px;
        background-color: #227ba9;
        color: white;
    }

    @media (min-width: $breakpoint-tablet) {
        display: none;
    }
}

.pic {

    display: none;

    @media (min-width: $breakpoint-tablet) {
        display: block;
        width: 400px;
        height: 300px;
    }

    @media (min-width: $breakpoint-desktop) {
        display: block;
        width: 618px;
        height: 456px;
    }
}