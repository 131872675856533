* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
}

html {
    scroll-behavior: smooth;
}

ul {
    list-style: none;
    padding-left: 0 !important;
}

a {
    text-decoration: none;
}

#home{
    display: flex;
    flex-direction: column;
    align-items: center;
}