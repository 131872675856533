$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;

.main {
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 110px;
  @media (min-width: $breakpoint-desktop) {
    margin-top: 140px;
  }
  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: #227ba9;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  h2 {
    margin-top: 30px;
  }
}

.contactMain {
  background-color: white;
  margin-top: 30px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.5);
  width: 90%;
  border-radius: 8px;
  @media (min-width: $breakpoint-desktop) {
    padding: 84px;
  }
  @media (min-width: $breakpoint-tablet) {
    width: 90%;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    p {
      margin: 0;
    }
    @media (min-width: $breakpoint-tablet) {
      gap: 0px;
    }
    @media (min-width: $breakpoint-desktop) {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }
    .infoCard {
      text-align: center;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      width: 40%;
      gap: 20px;
      @media (min-width: $breakpoint-desktop) {
        font-size: 16px;
      }
      cursor: pointer;
      .logoContact {
        background-color: #227ba9;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
      }
    }
  }
}

.Subscribe {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
}
