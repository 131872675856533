$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;

#Course {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    gap: 20px;
    @media (min-width: $breakpoint-desktop) {
        margin-top: 130px;
        flex-direction: row;
        padding: 0;
    }
}

#coursePage {
    width: 100%;
    @media (min-width: $breakpoint-desktop) {
        margin-top: 30px;
        margin-left: 20px;
    } 
}