$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;


footer{
    li {
        cursor: pointer;
        &:hover {
            color: #227ba9;
        }
    }
    a {
        cursor: pointer;
        text-decoration: none;
        color: black;
        &:hover {
            color: #227ba9;
        }
    }
    margin-top: 30px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 85rem;
        width: 100%;
        padding: 3rem 1rem;
        gap: 3rem;
        @media (min-width: $breakpoint-desktop) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            padding: 5rem 1rem;
        }
        .logo{
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                width: 90px;
            };
            p{
                text-align: center;
                margin-top: 25px;
                max-width: 17rem;
            }
            .social-icons{
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 2.5rem;
                p {
                    margin: 0;
                }
                img{
                    background-color: #858fa1;
                    padding: 8px;
                    width: 2.2rem;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        };
        .courses{
            display: flex;
            flex-direction: column;
            align-items: center;
            ul{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                text-align: center;
                margin-top: 25px;
            }
            @media (min-width: $breakpoint-desktop) {
                align-items: flex-start;
                ul{
                    text-align: left;
                }
            }
        }
        .menu-list{
            @extend .courses;
        }
        .contact{
            h3{
                margin-bottom: 25px;
                text-align: center;
            }
            .contact-info{
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-top: 20px;
                p {
                    margin-bottom: 0;
                }
            }
            @media (min-width: $breakpoint-desktop) {
                h3{
                    text-align: left;
                }
                .contact-info{
                    align-items: flex-start;
                }
            }
        }
    }
    .site-rights{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 100%;
        gap: 1rem;
        padding: 1rem;
        max-width: 85rem;
        @media (min-width: $breakpoint-tablet) {
            gap: 5rem;
            font-size: 14px;
        }
        @media (min-width: $breakpoint-desktop) {
            justify-content: space-between;
        }
    }
}