$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;

#teacher {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 100px;
    gap: 50px;

    .left-side {
        display: flex;
        justify-content: center;
        img {
            width: 270px;
            border-radius: 8px;
        }
    }

    .right-side {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @media (min-width: $breakpoint-desktop) {
        margin-top: 130px;
        text-align: start;
        flex-direction: row;
        justify-content: center;
        gap: 120px;
        p, h3 {
            width: 100%;
        }
        .right-side {
            width: 50%;
            border-left: 1px solid rgb(204, 199, 199);
            padding-left: 40px;
        }
    }
}